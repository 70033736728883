import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "pages/home";
import PageView from "components/page-view";
import GoogleAnalytics from "components/google-analytics";
import ComingSoonPage from "pages/coming";

export default function App() {
    return <>
        <Router>
            <PageView/>
            <GoogleAnalytics/>
            <Routes>
                <Route exact path='/' element={<ComingSoonPage/>}/>
            </Routes>
        </Router>
    </>
}
