import Services from "components/services";
import Navigation from "components/navigation";
import Banner from "components/banner";

export default function ComingSoonPage() {
    return <>
        {/*<Navigation/>*/}
        {/*<Banner/>*/}
        {/*<Services/>*/}
        <div className="flex items-center justify-center h-screen bg-gray-200">
            <div className="container">
                <div className="bg-white rounded-lg shadow-lg p-5 md:p-20 mx-2">
                    <div className="text-center">
                        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                            JAQ<span className="text-orange-600">Dietitians</span>
                        </h2>
                        <h3 className='text-xl md:text-3xl mt-10'>Coming Soon</h3>
                        <p className="text-md md:text-xl mt-10">
                            <a className="hover:underline"
                               href="https://www.jaqdietitians.co.za">JAQ Dietitians</a> website will bring healthy
                            eating tips and lifestyle changes to help you lead a more productive lifestyle.</p>
                    </div>
                    <div className="flex flex-wrap mt-10 justify-center">
                        <div className="m-3">
                            <a href="https://www.facebook.com/JAQDietitians" title="JAQ Dietitians On Facebook"
                               className="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-blue-600 hover:border-blue-600 hover:bg-blue-600 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                                <span className="mx-auto">Facebook</span>
                            </a>
                        </div>
                        <div className="m-3">
                            <a href="https://www.youtube.com/channel/UCilVsIHwMAlwA50zovFGw8w"
                               title="JAQ Dietitians On Youtube"
                               className="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-red-600 hover:border-red-600 hover:bg-red-600 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                                <span className="mx-auto">YouTube</span>
                            </a>
                        </div>
                        <div className="m-3">
                            <a href="https://www.instagram.com/jaqdietitians/" title="JAQ Dietitians On Instagram"
                               className="md:w-32 bg-white tracking-wide text-gray-800 font-bold rounded border-2 border-orange-500 hover:border-orange-500 hover:bg-orange-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center">
                                <span className="mx-auto">Instagram</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
